.head-text {
  position: relative;
}

.text-on-image {
  position: absolute;
  left: 5%;
  top: 1%;
}

.text-on-image-section1{
  position: absolute;
  left: 5%;
  top: 30%;
  right: 20%;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-text-on-image {
  position: absolute;
  left: 20%;
  top: 1%;
  text-align: center;
}
.contact-text-on-image-new{
  position: absolute;
  top: 1%;
  text-align: center;
  left:10%;
}

.form-text-on-image {
  position: absolute;
  right: 1%;
  bottom: 10%;
  text-align: center;
}

.hidden{
  display: none;
}